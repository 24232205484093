export const getAuthors = authors => {
  const authorsNames = authors.map(author => {
    const { name } = author
    return name
  })
  if (authorsNames.length > 1) {
    let authorPopped = authorsNames.pop()
    return ` ${authorsNames.join(", ")} and ${authorPopped} `
  } else {
    return authorsNames.join(" and ")
  }
}

export const getAuthorsBio = authors => {
  const authorsBios = authors.map(author => {
    const { name, description } = author
    if (authors.length > 1) {
      return `<p><strong>${name}</strong><br />${description}</p>`
    }
    return `<p>${description}</p>`
  })
  return authorsBios.join("")
}
