import React, { useState, useEffect } from "react"
import clsx from "clsx"
import { Link } from "gatsby"
import isEmpty from "lodash/isEmpty"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { getAuthors, getAuthorsBio } from "../helpers/post"
import useAllWordpressPostTotalCount from "../data/allWordpressPostTotalCount"

const Post = props => {
  const { data } = props
  const { pageContext } = props
  const { next } = pageContext
  const { totalCount } = useAllWordpressPostTotalCount()

  const { wordpressPost } = data
  const { title, content, categories, yoast_head, sticky } = wordpressPost

  const [readingProgress, setReadinProgress] = useState(0)
  const [bioOpen, setBioOpen] = useState(false)
  const [shareLinkOpen, setShareLinkOpen] = useState(false)

  useEffect(() => {
    setReadinProgress(0)
  }, [])
  useEffect(() => {
    document.addEventListener(
      "scroll",
      () => {
        if (bioOpen) {
          setBioOpen(false)
        }
        if (shareLinkOpen) {
          setShareLinkOpen(false)
        }
        const scrollTop =
          document.documentElement["scrollTop"] || document.body["scrollTop"]
        const scrollBottom =
          (document.documentElement["scrollHeight"] ||
            document.body["scrollHeight"]) -
          document.documentElement.clientHeight
        const scrollPercent = (scrollTop / scrollBottom) * 100
        setReadinProgress(scrollPercent)
      },
      { passive: true }
    )
  })

  const authorsString = getAuthors(categories)
  return (
    <Layout
      type="single-post"
      pathname={props.location.pathname}
      isBlurred={bioOpen || shareLinkOpen}
    >
      <SEO bodyClasses="single-post" yoast_head={yoast_head} />
      <div
        className={clsx("single_post__wrap", {
          blurred: bioOpen || shareLinkOpen,
        })}
      >
        <div className="single_post__progress-bar">
          <span
            className="single_post__progress-bar__readed"
            style={{ width: `${readingProgress}%` }}
          ></span>
        </div>
        <article className="single_post">
          <div className="inner">
            {sticky ? (
              <h1>
                <strong dangerouslySetInnerHTML={{ __html: title }} />
                {authorsString !== "Anonym" && (
                  <>
                    <br />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getAuthors(categories),
                      }}
                    />
                    <br />
                    <a
                      className="btn show-bio"
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        setBioOpen(!bioOpen)
                      }}
                    >
                      Biography
                    </a>
                  </>
                )}
              </h1>
            ) : (
              <h1>
                {authorsString !== "Anonym" && (
                  <>
                    <strong
                      dangerouslySetInnerHTML={{
                        __html: getAuthors(categories),
                      }}
                    />
                    <a
                      className="btn show-bio"
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        setBioOpen(!bioOpen)
                      }}
                    >
                      Biography
                    </a>
                    <br />
                  </>
                )}
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </h1>
            )}

            {content.includes("<!--more-->") ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: content.split("<!--more-->")[1],
                }}
              />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: content }} />
            )}

            <a
              className="btn single_post__share-link"
              href="#"
              onClick={e => {
                e.preventDefault()
                setShareLinkOpen(true)
              }}
            >
              Share
            </a>
            <div className="single_post__footnotes"></div>
          </div>
        </article>
        <div className="single_post__related">
          <article className="single_post__related__item single_post__related__item--gray">
            <Link to="/">
              <h4>Browse all {totalCount} essays</h4>
              <span className="btn btn--white">Essays</span>
            </Link>
          </article>
          {!isEmpty(next) && (
            <article className="single_post__related__item single_post__related__item--black">
              <Link to={next.slug}>
                <h4>{`${getAuthors(next.categories)}: ${next.title}`}</h4>
                <span className="btn btn--white">Read Next</span>
              </Link>
            </article>
          )}
        </div>
      </div>
      {authorsString !== "Anonym" && (
        <div
          className={clsx("single_post__popup", "single_post__biography", {
            visible: bioOpen,
          })}
          onClick={e => {
            if (
              e.target.classList.contains("single_post__biography__inner") ===
                false &&
              e.target.parentNode.classList.contains(
                "single_post__biography__inner"
              ) === false
            ) {
              setBioOpen(false)
            }
          }}
        >
          <a
            className="close_popup"
            href="#"
            onClick={e => {
              e.preventDefault()
              setBioOpen(false)
            }}
          ></a>
          <div className="inner">
            <div
              className="single_post__biography__inner"
              dangerouslySetInnerHTML={{ __html: getAuthorsBio(categories) }}
            />
          </div>
        </div>
      )}
      <div
        className={clsx("single_post__popup", "single_post__share", {
          visible: shareLinkOpen,
        })}
        onClick={e => {
          if (
            e.target.tagName !== "A" &&
            e.target.tagName !== "LI" &&
            e.target.tagName !== "SPAN"
          ) {
            setShareLinkOpen(false)
          }
        }}
      >
        <a
          className="close_popup"
          href="#"
          onClick={e => {
            e.preventDefault()
            setShareLinkOpen(false)
          }}
        ></a>
        <ul>
          <li>
            <a
              className="btn btn--light clipboard_link clipboard_link--article"
              href="#"
              onClick={e => {
                e.preventDefault()
              }}
            >
              <span>Share Article</span>
            </a>
          </li>
          <li>
            <a
              className="btn btn--light clipboard_link  clipboard_link--project"
              href="#"
              onClick={e => {
                e.preventDefault()
              }}
            >
              <span>Share Project</span>
            </a>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default Post

export const postQuery = graphql`
  query($id: String) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      categories {
        name
        description
      }
      sticky
      yoast_head
    }
  }
`
