import { graphql, useStaticQuery } from "gatsby"

const AllWordpressPostsTotalCount = () => {
  const { allWordpressPost } = useStaticQuery(graphql`
    query {
      allWordpressPost {
        totalCount
      }
    }
  `)

  return allWordpressPost
}

export default AllWordpressPostsTotalCount
